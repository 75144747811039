import AlertIcon from '@solar/assets/images/status/Alert';
import TickIcon from '@solar/assets/images/status/Tick';
import React, { useEffect, useState } from 'react';

interface AlertProps {
  message: string;
  type: 'success' | 'error';
  onClose: () => void;
  duration?: number;
}

function Alert({ message, type, duration, onClose }: AlertProps) {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
      onClose();
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onClose]);

  if (!isVisible) return null;

  return (
    <div
      className={`absolute fixed top-8 right-1/2 transform translate-x-1/2 p-2 rounded flex items-center gap-2 z-1000 font-rp-basetica text-sm ${
        type === 'success'
          ? 'bg-solar-alert-success text-solar-alert-green'
          : 'bg-rp-surface-alert-error text-rp-red-3'
      }`}
    >
      <span>
        {type === 'success' ? <TickIcon color="#214E0A" /> : <AlertIcon color="#7C1F20" />}
      </span>
      <p>{message}</p>
    </div>
  );
}

Alert.defaultProps = {
  duration: 3000,
};

export default Alert;
