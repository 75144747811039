import React from 'react';
import useMarketingModule from '@hooks/useMarketingModuleLogic';
import Heading from '@solar/common/Heading/Heading';
import Link from 'next/link';
import ImageFallback from '@components/common/Image/ImageFallback';
import { getCdnImageUrl } from '@utils/cloudflare';
import withPromoLogic, { PromoLogicProps } from '../CaptureEmail/withPromoLogic';

const promoConfig = {
  original: {
    image: 'home-v2/marketing_module_v2.png',
    pre_submission_title: 'Be the first to know',
    pre_submission_subtitle: 'Sign up today to receive exclusive promotions and recommendations.',
    pre_submission_button_label: 'Get Offer',
    post_submission_title: 'Confirmed',
    post_submission_subtitle: 'Check your inbox for weekly updates and inspiration.',
    already_submitted_title: 'Recharge and reset',
    already_submitted_subtitle:
      "Today's the perfect day to book a relaxing and memorable daycation at a luxury pool or spa.",
    already_submitted_button_label: 'Explore',
  },

  sale: {
    image: 'home-v2/marketing-module-image-spring-break.jpg',
    pre_submission_title: 'Upgrade your Spring Break with $50 off',
    pre_submission_subtitle: 'Sign up now to receive your discount code.',
    pre_submission_button_label: 'Get Offer',
    post_submission_title: 'Confirmed',
    post_submission_subtitle:
      'Enjoy $50 off bookings $200+. Check your email for your exclusive code.',
    already_submitted_title: 'Upgrade your Spring Break',
    already_submitted_subtitle: 'Level up your vacation with a luxury pool or spa day.',
    already_submitted_button_label: 'Explore',
  },
};

function HomePageMarketingModule({ handleClick, springSaleCampaign }: PromoLogicProps) {
  const { currentStep, email, handleCTAClick, handleSubmit, setEmail } =
    useMarketingModule(handleClick);

  return (
    <div className="bg-solar-surface-brand-shadow flex flex-col items-center justify-center pb-10 pt-172px relative rounded-xl w-full d:flex-row d:pb-50px d:pt-58px d:h-370">
      <div className="absolute h-180px -top-32px w-233px d:relative d:top-0 d:w-544px d:h-420px">
        <ImageFallback
          className="rounded-xl"
          src={getCdnImageUrl(
            500,
            promoConfig[springSaleCampaign].image,
            'https://static-assets.resortpass.com/assets/',
            100,
          )}
          blurDataUrl={getCdnImageUrl(
            50,
            promoConfig[springSaleCampaign].image,
            'https://static-assets.resortpass.com/assets/',
            10,
          )}
          alt={promoConfig[springSaleCampaign].pre_submission_title || ''}
          title={promoConfig[springSaleCampaign].pre_submission_title || ''}
          loading="eager"
          layout="fill"
          objectFit="cover"
          quality={100}
        />
      </div>

      <div className="flex flex-col max-w-624px ml-0 px-spacing-lg text-solar-surface-secondary d:px-0 d:ml-11 d:pr-11 w-full">
        <Heading level={2} className="max-w-295 d:w-full d:max-w-none">
          {currentStep === 1 && promoConfig[springSaleCampaign].pre_submission_title}
          {currentStep === 2 && promoConfig[springSaleCampaign].post_submission_title}
          {currentStep === 3 && promoConfig[springSaleCampaign].already_submitted_title}
        </Heading>

        <Heading level={5} className="flex flex-col mt-spacing-md mb-spacing-lg d:mb-spacing-xl">
          {currentStep === 1 && promoConfig[springSaleCampaign].pre_submission_subtitle}
          {currentStep === 2 && promoConfig[springSaleCampaign].post_submission_subtitle}
          {currentStep === 3 && promoConfig[springSaleCampaign].already_submitted_subtitle}

          {currentStep === 1 ? (
            <>
              {' '}
              <Link href="/terms-conditions" passHref>
                <a target="_blank" className="hover:underline">
                  Terms and Conditions apply.
                </a>
              </Link>
            </>
          ) : null}
        </Heading>

        {currentStep === 1 && (
          <div className="bg-solar-surface-primary flex justify-between rounded-4 border border-rp-gray-divider p-2 d:w-90">
            <input
              className="mr-4 outline-none text-base text-solar-secondary w-full placeholder-text-solar-secondary"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email address"
            />

            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
              className="bg-solar-surface-brand-shadow text-solar-inverted-primary flex-shrink-0 font-rp-pn-semi-bold h-9 text-base rounded-4 w-93px"
            >
              {promoConfig[springSaleCampaign].pre_submission_button_label}
            </button>
          </div>
        )}

        {currentStep === 3 && promoConfig[springSaleCampaign].already_submitted_button_label && (
          <button
            type="button"
            onClick={handleCTAClick}
            className="font-rp-basetica bg-solar-button-secondary capitalize text-base border border-solar-button rounded-4 h-11 text-solar-primary w-full d:w-185px"
          >
            {promoConfig[springSaleCampaign].already_submitted_button_label}
          </button>
        )}
      </div>
    </div>
  );
}

export default withPromoLogic(HomePageMarketingModule, 'HomePageMarketingModule');
