/* eslint-disable @typescript-eslint/indent */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useCallback, useEffect, useState } from 'react';
import Head from 'next/head';
import { getPopularHotels, getNewAndNotableHotels, getProductCategories } from '@utils/services';
import { useCookies } from 'react-cookie';
import useSessionStorage from '@hooks/useSessionStorage';
import sessionStorageKeys from '@constants/sessionStorageKeys';
import { homePageView } from '@events/HomePage';
import HomePage from '@components/HomePage/HomePage';
import { PopularHotel } from '@customTypes/popular-hotel-data';
import HotelApi from '@customTypes/hotel-api';
import Layout from '@components/Layout/Layout';
import { useRouter } from 'next/router';
import { GetHostName } from '@helpers/environment';
import Alert from '@solar/common/Alert/Alert';
import { getExperimentValue } from '@utils/amplitudeSSR';
import { SpringSaleCampaignFeatureFlagType } from '@context/FeatureFlagContext';
import { getServerSideUserLocation } from '@context/GeoIpDetectionContext';
import { Location } from '@components/Hotels/types';
import { CATEGORY_SEARCH_HOMEPAGE_VIEWED } from '@constants/amplitudeEvents';
import { useEvents } from '@events/EventsProvider';

type IndexPageProps = {
  popularHotels: PopularHotel[];
  newAndNotableHotels: HotelApi[];
  userLocation: Location | null;
  searchExperiment: 'on' | 'exclude' | null;
};

const getPopularHotelsData = async () =>
  getPopularHotels()
    .then((response) => ({
      showPopularHotels: true,
      popularHotels: response?.hotels,
    }))
    .catch(() => ({
      showPopularHotels: false,
      popularHotels: null,
    }));

export async function getServerSideProps(context: any) {
  const [
    popularHotels,
    initialProductCategories,
    newAndNotableHotels,
    springSaleCampaign,
    searchExperiment,
    userLocation,
  ] = await Promise.all([
    getPopularHotelsData(),
    getProductCategories(),
    getNewAndNotableHotels(),
    getExperimentValue<SpringSaleCampaignFeatureFlagType>(context, 'spring-break-campaign').catch(
      () => null,
    ),
    getExperimentValue<'on' | null>(context, 'new-search').catch(() => null),
    getServerSideUserLocation(context),
  ]);

  return {
    props: {
      ...popularHotels,
      initialProductCategories,
      newAndNotableHotels,
      springSaleCampaign,
      searchExperiment,
      userLocation,
    },
  };
}

export default function Home({
  popularHotels,
  newAndNotableHotels,
  userLocation,
  searchExperiment,
}: IndexPageProps) {
  const [homePageViewFired, setHomePageViewFired] = useState<boolean>(false);

  const { getItem } = useSessionStorage();
  const date = getItem(sessionStorageKeys.SMART_CALENDAR_DATE);
  const sessionID = getItem(sessionStorageKeys.SESSION_ID);
  const [cookies] = useCookies();
  const { track } = useEvents();
  const router = useRouter();

  // GA4 Page View Event
  useEffect(() => {
    if (!homePageViewFired && sessionID) {
      homePageView(cookies.userInformation, sessionID, date);
      setHomePageViewFired(true);
    }
  }, [cookies.userInformation, date, homePageViewFired, sessionID]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    if (searchExperiment === 'on') {
      track(CATEGORY_SEARCH_HOMEPAGE_VIEWED, {});
    }
  }, [searchExperiment, track]);

  const [toast, setToast] = useState<{ message: string; type: 'success' | 'error' } | null>(null);

  const showToast = useCallback(
    (type: 'success' | 'error', message: string = '') => {
      setToast({ message, type });
    },
    [setToast],
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { update_password_success, ...rest } = router.query;
    if (update_password_success) {
      showToast('success', 'New password saved successfully');
      router.replace(
        {
          pathname: router.pathname,
          query: rest,
        },
        undefined,
        { shallow: true },
      );
    }
  }, [router, router.query.update_password_success, showToast]);

  return (
    <>
      <Head>
        <link rel="canonical" href={GetHostName()} />
      </Head>

      {toast && (
        <Alert
          message={toast.message}
          type={toast.type}
          onClose={() => {
            setToast(null);
          }}
        />
      )}

      <HomePage
        popularHotels={popularHotels}
        newAndNotableHotels={newAndNotableHotels}
        userCityAndState={userLocation?.name || ''}
      />
    </>
  );
}

Home.getLayout = (page: React.ReactElement) => (
  <Layout
    userLocation={page.props.userLocation}
    initialProductCategories={page.props.initialProductCategories}
    searchBarEnabled={false}
  >
    {page}
  </Layout>
);
