import React from 'react';

type Props = {
  color?: string;
  className?: string;
  direction?: 'up' | 'down' | 'left' | 'right';
};

export default function ChevronIcon({
  direction = 'left',
  color = '#22272A',
  className = '',
}: Props) {
  if (direction === 'left') {
    return (
      <svg
        className={className}
        width="8"
        height="12"
        viewBox="0 0 8 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.63512 0.0107168C6.60118 0.0192433 6.54888 0.0433672 6.51891 0.0643226C6.48892 0.085278 5.23906 1.32906 3.74143 2.82827C1.19672 5.37569 1.01561 5.55973 0.974771 5.63976C0.907427 5.77176 0.887695 5.85351 0.887695 6.00064C0.887695 6.14777 0.907427 6.22952 0.974771 6.36152C1.01561 6.44156 1.19677 6.62561 3.74754 9.17865C5.24854 10.681 6.5014 11.926 6.53168 11.9455C6.63592 12.0124 6.79328 12.0184 6.90764 11.9598C6.96664 11.9295 7.04125 11.8549 7.07149 11.7959C7.12843 11.6848 7.1243 11.5293 7.06175 11.4276C7.04469 11.3998 5.8216 10.1674 4.34379 8.68888L1.65683 6.00064L4.34379 3.3124C5.8216 1.83386 7.04469 0.601457 7.06175 0.573712C7.1243 0.471956 7.12843 0.316436 7.07149 0.205383C6.99388 0.0539856 6.80257 -0.0313653 6.63512 0.0107168Z"
          fill={color}
        />
      </svg>
    );
  }

  if (direction === 'right') {
    return (
      <svg
        className={className}
        width="8"
        height="12"
        viewBox="0 0 8 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.19179 0.0113262C1.08622 0.0322127 0.978514 0.113619 0.929233 0.209748C0.872321 0.32076 0.876442 0.476223 0.938967 0.57794C0.956026 0.605675 2.17866 1.83763 3.65593 3.31562L6.34189 6.00286L3.65593 8.69011C2.17866 10.1681 0.956026 11.4 0.938967 11.4278C0.876442 11.5295 0.872321 11.685 0.929233 11.796C0.959462 11.855 1.03404 11.9295 1.09302 11.9598C1.20734 12.0184 1.36465 12.0124 1.46885 11.9455C1.49911 11.9261 2.75151 10.6815 4.25195 9.17969C6.80178 6.6276 6.98288 6.44362 7.0237 6.36361C7.09102 6.23166 7.11074 6.14994 7.11074 6.00286C7.11074 5.85579 7.09102 5.77406 7.0237 5.64212C6.98288 5.56212 6.80183 5.37814 4.25807 2.83167C2.76099 1.33301 1.51171 0.0897729 1.48189 0.0689353C1.43046 0.0329709 1.32013 -0.00236992 1.26707 0.000124728C1.25362 0.000760618 1.21975 0.00579882 1.19179 0.0113262Z"
          fill={color}
        />
      </svg>
    );
  }

  if (direction === 'up') {
    return (
      <svg
        className={className}
        width="12"
        height="8"
        viewBox="0 0 12 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.84314 0.909318C5.73872 0.933406 5.65774 0.966762 5.58416 1.01598C5.54698 1.04085 4.35478 2.22468 2.78398 3.7965C0.116136 6.46608 0.0479007 6.53548 0.0220982 6.60564C-0.0109702 6.69557 -0.00660581 6.80954 0.0330451 6.89145C0.0645787 6.9566 0.141614 7.03653 0.203075 7.06788C0.295062 7.11481 0.435573 7.11685 0.533267 7.07268C0.565388 7.05816 1.40725 6.22459 3.28628 4.34681L5.99302 1.64186L8.69976 4.34681C10.5788 6.22459 11.4206 7.05816 11.4528 7.07268C11.5475 7.11552 11.6913 7.11486 11.7765 7.07119C11.8643 7.02621 11.9573 6.91736 11.9712 6.84347C11.9753 6.82133 11.9835 6.80025 11.9894 6.79664C11.9952 6.79303 12 6.75712 12 6.71684C12 6.67656 11.9965 6.64715 11.9921 6.65149C11.9878 6.65582 11.9717 6.62849 11.9564 6.59076C11.9303 6.5267 11.7475 6.34111 9.19754 3.79061C7.39047 1.98313 6.44228 1.04296 6.39468 1.01145C6.24935 0.915229 6.00977 0.870854 5.84314 0.909318ZM0.00185912 6.73154C0.00185912 6.7744 0.0040413 6.79194 0.00669108 6.7705C0.00935286 6.74907 0.00935286 6.71401 0.00669108 6.69257C0.0040413 6.67113 0.00185912 6.68867 0.00185912 6.73154Z"
          fill={color}
        />
      </svg>
    );
  }

  // down
  return (
    <svg
      className={className}
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.303959 0.894413C0.187629 0.91823 0.0852574 1.00127 0.0328297 1.11434C-0.00984874 1.20635 -0.0110475 1.33935 0.0300041 1.42702C0.053172 1.47652 0.556886 1.98611 2.79981 4.22919C5.24108 6.67065 5.55043 6.97588 5.62479 7.01668C5.76208 7.09199 5.83913 7.11145 6 7.11145C6.16087 7.11145 6.23792 7.09199 6.37521 7.01668C6.44957 6.97588 6.75892 6.67065 9.20019 4.22919C11.4431 1.98611 11.9468 1.47652 11.97 1.42702C12.011 1.33935 12.0098 1.20635 11.9672 1.11434C11.8911 0.950364 11.7251 0.863135 11.5496 0.894952C11.5097 0.902193 11.4543 0.922071 11.4265 0.93911C11.3988 0.956162 10.1665 2.17916 8.68807 3.65689L6.00005 6.34367L3.29975 3.64524C0.937261 1.2844 0.591784 0.943685 0.53828 0.921826C0.463455 0.891257 0.371738 0.88053 0.303959 0.894413Z"
        fill={color}
      />
    </svg>
  );
}
